import { WebAuth, Auth0Error, Auth0Callback } from "auth0-js"
export type OAuthType = "google-oauth2" | "facebook"

type SendMagicLink = (
  email: string,
  onSuccess: () => void,
  onFailure: (e: Auth0Error) => void
) => (e: React.SyntheticEvent) => void

type SendEmailPasswordSignUp = (
  email: string,
  password: string,
  handleError: Auth0Callback<unknown, Auth0Error>
) => (e: React.SyntheticEvent) => void

type PasswordLogin = (
  email: string,
  password: string,
  handleError: Auth0Callback<unknown, Auth0Error>
) => (e: React.SyntheticEvent) => void

type HandleOAuth = (oauthType: OAuthType) => (e: React.SyntheticEvent) => void

type ResetPassword = (
  email: string,
  handleError: (err: Auth0Error) => void
) => void

export const authFunctions = (
  trackingPageName: string,
  auth0Client: WebAuth
): {
  sendMagicLink: SendMagicLink
  sendEmailPasswordSignUp: SendEmailPasswordSignUp
  passwordLogin: PasswordLogin
  handleOAuth: HandleOAuth
  resetPassword: ResetPassword
} => {
  const sendMagicLink: SendMagicLink = (email, onSuccess, onFailure) => e => {
    e.preventDefault()
    if (auth0Client !== undefined) {
      auth0Client.passwordlessStart(
        {
          connection: "email",
          send: "link",
          email: email,
        },
        function (err: Auth0Error | null): void {
          if (err) {
            onFailure(err)
          } else {
            onSuccess()
          }
        }
      )
    }
  }

  const sendEmailPasswordSignUp: SendEmailPasswordSignUp =
    (email, password, handleError) => e => {
      e.preventDefault()
      if (auth0Client !== undefined) {
        auth0Client.redirect.signupAndLogin(
          {
            connection: "HabitoLegacyDB",
            email,
            password,
          },
          handleError
        )
      }
    }

  const passwordLogin: PasswordLogin = (email, password, handleError) => e => {
    e.preventDefault()

    if (auth0Client !== undefined) {
      //auth0Client.redirect.loginWithCredentials(
      auth0Client.login(
        {
          realm: "HabitoLegacyDB",
          email,
          password,
        },
        handleError
      )
    }
  }

  const handleOAuth: HandleOAuth = oauthType => e => {
    e.preventDefault()
    if (auth0Client !== undefined) {
      auth0Client.authorize({
        connection: oauthType,
      })
    }
  }

  const resetPassword: ResetPassword = (email, handleError) => {
    if (auth0Client !== undefined) {
      auth0Client.changePassword(
        {
          connection: "HabitoLegacyDB",
          email,
        },
        function (err) {
          if (err) {
            handleError(err)
          }
        }
      )
    }
  }

  return {
    sendMagicLink,
    sendEmailPasswordSignUp,
    passwordLogin,
    handleOAuth,
    resetPassword,
  }
}
